@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&family=Titillium+Web:wght@300;400;700&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --mainColor: white;
  --mainColorLight: #5767aa;
  --secondaryColor: #DB2B39;
  --textColor: black;
}

body {
  font-family: 'Roboto', sans-serif;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% 100%;
  position: relative;
  background-color: #E8E8E8;
}

body::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7));
  z-index: -1;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  padding: 0 2rem;
  background-color: var(--mainColor);
  color: var(--textColor);
  opacity: 0.9;
}

.nav-logo {
  display: flex;
  align-items: center;
}

/* Set a background color specifically for .nav-logo within header */
header .nav-logo {
  background-color: transparent;
}

nav {
  display: flex;
  align-items: center;  
  transition: transform 0.3s ease-in-out;
  text-align: center; /* Center the navigation links */
  justify-content: right;
  font-size: 15px;
}

nav a {
  display: block;
  padding: 20px 15px; /* Adjust padding */
  color: var(--textColor);
  font-size: 23px; /* Adjust font size */
  transition: color 0.3s ease-in-out;
  margin: 15px 0; /* Add margin for spacing */
}

nav a:hover {
  color: grey;
  text-decoration: underline;
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--textColor);
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

.nav-logo img {
  height: 80px;
  max-width: 300px;
  width: 100%;
}

.nav-email-link {
  background-color: rgb(115, 115, 164);
  justify-content: flex-end;
  height: 25px;
  display: flex;
  align-items: center;
}

.nav-email-link a {
  color: white;
  margin-right: 1%;
}

@media only screen and (max-width: 768px) {
  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  header nav {
    flex-direction: column;
    width: 100%;
    position: absolute;
    left: 0;
    background-color: var(--mainColor);
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out;
  }

  header .nav-open {
    transform: translateY(0);
    top: 0px;
    opacity: 1;
    background-color: rgba(255, 255, 255, 1); /* Darken the background color */
  }

  header .nav-open a {
    font-weight: bold;
    font-size: 25px;
  }

  nav .nav-close-btn {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 2rem; /* Increase font size */
    color: black; /* Set color to the main background color */
  }

  .nav-logo img {
    height: 60px;
    margin-right: 0;
    object-fit: contain;
  }

  .nav-logo {
    justify-content: left;
  }

  .nav-email-link {
    background-color: rgb(115, 115, 164);
    justify-content: flex-end;
    height: 25px;
    display: flex;
    align-items: center;
  }
  
  .nav-email-link a {
    color: white;
    margin-right: 1%;
  }
}

@media only screen and (min-width: 769px) {
  nav {
    justify-content: flex-end; /* Align navigation links to the right */
    margin-right: 20px; /* Add margin to the right of navigation links */
  }

  header .nav-btn {
    display: none; /* Hide hamburger menu button for normal screen size */
  }
}


