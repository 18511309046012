/* General Styles */
h1 {
    padding-top: 3%;
    text-align: center;
    font-family: Arial, sans-serif;
    color: #333;
}



/* Capabilities Section */
.capabilities {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
}

.capabilities-text,
.capabilities-image {
    width: 100%;
    max-width: 800px;
    padding: 20px;
    margin: 20px 0;
    border: 2px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
}

.capabilities-image {
    overflow: hidden;
    margin-bottom: 20px;
}

.capabilities-image img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 8px;
}

/* CNC Machines Section */
.cnc-machines-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
}

.cnc-machines-text {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    padding: 20px;
    margin: 20px 0;
    border: 2px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
}

.cnc-machines-text-1-2-3,
.cnc-machines-text-4-5 {
    margin: 20px 0;
}

.cnc-machines-text-1-2-3 div,
.cnc-machines-text-4-5 div {
    margin: 10px 0;
}

.cnc-machines-text h1 {
    padding-bottom: 3%;
}

/* Responsive Styles */
@media (min-width: 768px) {
    .capabilities,
    .cnc-machines-container {
        flex-direction: row;
        align-items: start;
    }

    .capabilities-text,
    .cnc-machines-text {
        margin: 0 20px 0 0;
    }

    .capabilities-image {
        margin: 0 0 0 20px;
    }

    .cnc-machines-text-1-2-3,
    .cnc-machines-text-4-5 {
        margin: 0 20px 0 0;
    }

    .cnc-machines-text-1-2-3 div,
    .cnc-machines-text-4-5 div {
        margin: 10px 0;
    }
}

/* Tools and Equipment Section */
.tools-equipment-section {
    padding: 40px 20px;
}

.get-in-touch {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
    margin: 20px 0;
    text-align: center;
    position: relative;
}

/* Responsive Styles for Get in Touch Section */
@media (min-width: 768px) {
    .get-in-touch {
        flex-direction: row;
    }
}

.contact-us {
    margin-top: 40px;
    border-top: 2px solid #ddd;
    padding-top: 40px;
    max-width: 1160px;
    margin-left: auto;
    margin-right: auto;
}

/* Additional Styles */
ul {
    list-style-type: none;
    padding: 0;
}

ul li {
    margin-bottom: 10px;
    line-height: 1.5;
}
