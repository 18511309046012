/* Industries Section */
.industries {
  padding: 40px 20px;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  }

.industries.loaded {
  opacity: 1;
}
  
.industries-container-first {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding-top: 20px;
  overflow: hidden;
  
}

.industries-container-item img{
  height: 300px;
  width: 300px;
  
}


.industries-container-second {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding-top: 40px;
  overflow: hidden;
}

.industries-container-third {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding-top: 40px;
  overflow: hidden;
}

.services-intro{
  padding-left: 12%;
  padding-right: 12%;
  text-align: justify;
  background-color: #cce0ff;
}

.industries-container-text h1{
  font-size: 95%;
  padding-bottom: 4%;
}

.industries-container-text {
  width: 300px;
  text-align: left;
}

.industries-container-text ul{
  list-style-position:initial;
  padding-left: 4%;
}

.industries-container-text li {
  font-size: 95%;
}


.get-in-touch {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px; /* Increased padding for better spacing */
  margin: 20px 0;
  text-align: center;
  position: relative;
}

/* Responsive Styles for Get in Touch Section */
@media (min-width: 768px) {
  .get-in-touch {
      flex-direction: row;
  }
}

.contact-us {
  margin-top: 40px;
  border-top: 2px solid #333; /* Use a darker border color for better contrast */
  padding-top: 40px;
}