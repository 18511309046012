/* Footer styles */

footer {
  background-color: #333;
  color: #fff;
  padding: 20px;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-links li {
  display: inline-block;
  margin-right: 10px;
}

.footer-links li:last-child {
  margin-right: 0;
}

.footer-links a {
  color: #fff;
  text-decoration: none;
}

/* Media query for small screens */
@media screen and (max-width: 768px) {
  .footer-content {
      flex-direction: column;
      text-align: center;
  }

  .footer-links {
      margin-top: 10px;
  }

  .footer-links li {
      display: block;
      margin-bottom: 10px;
  }

  .footer-links li:last-child {
      margin-bottom: 0;
  }
}
